import { 
  ChakraProvider, 
  useDisclosure,
  Image,
  Spacer
} from "@chakra-ui/react";
import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import CreateEventModal from "./components/CreateEvent";
import MainItem from "./components/MainItem";
import EventListTable from "./components/EventList";
import "@fontsource/inter";
import { useEthers } from "@usedapp/core";

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { account } = useEthers();
  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <Image
          width="300px"
          objectFit="cover"
          src="./DAOQubeLogo2.png"
          alt="Cube Logo"
          mb={10}
        />
        <Spacer />
        <ConnectButton handleOpenModal={onOpen} />
        <AccountModal isOpen={isOpen} onClose={onClose} />
        <MainItem account = { account }/>
        <CreateEventModal account = { account }/>
        <EventListTable account = { account } mb={10}/>
      </Layout>
    </ChakraProvider>
  )
}

export default App;
